<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Classes for Questionnaire</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Class"
              invalid-feedback="Class is required."
              ref="class"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="clsIDs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                label="name"
                :reduce="(val) => val.id"
                :clearable="false"
                placeholder="Select classes"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button variant="success" @click="save()" block>
              <span> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-row align-h="center" v-if="dataLoading" class="mt-2">
      <b-spinner
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <b-row align-h="center" v-else>
      <b-col xl="6" lg="8" md="12">
        <b-card v-for="(pcard, cardIndex) in myObj" :key="cardIndex">
          <b-row>
            <b-col md="6" class="pr-md-0">
              <h4 class="mb-0">
                Interview Questionnaire
                <b-button
                  v-if="myObj.length > 1"
                  class="btn-icon"
                  variant="flat-danger"
                  pill
                  size="sm"
                  @click="removeCard(cardIndex)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </h4>
            </b-col>
            <b-col md="6" class="text-right">
              <b-button
                v-if="pcard.cIDs.length == 0"
                class="btn-icon"
                variant="primary"
                @click="AddOpen(cardIndex)"
              >
                <feather-icon icon="PlusIcon" />
                Classes
              </b-button>
              <div v-else>
                <b-badge
                  v-for="cls in pcard.cIDs"
                  :key="cls"
                  variant="light-primary"
                  class="m-25"
                >
                  <span>{{ getClassName(cls) }}</span>
                </b-badge>

                <feather-icon
                  icon="EditIcon"
                  size="18"
                  @click="Edit(cardIndex)"
                  class="ml-50 cursor-pointer"
                />
              </div>
            </b-col>
          </b-row>

          <hr />

          <b-row>
            <b-col md="12">
              <b-form-group
                label="Questionnaire"
                invalid-feedback="Questionnaire is required."
              >
                <div
                  v-for="(item, ind) in pcard.interviewData"
                  :key="ind"
                  class="d-flex align-items-center mb-1"
                >
                  <b-form-input
                    :ref="'q-' + cardIndex"
                    :placeholder="'Question ' + (ind + 1)"
                    v-model.trim="item.question"
                    @keyup.enter="addValue(pcard, cardIndex)"
                  />
                  <feather-icon
                    v-if="pcard.interviewData.length > 1"
                    icon="TrashIcon"
                    size="16"
                    class="cursor-pointer ml-50"
                    @click="removeValue(pcard.interviewData, ind)"
                  />
                  <feather-icon
                    icon="PlusIcon"
                    size="16"
                    class="cursor-pointer ml-50"
                    @click="addValue(pcard, cardIndex)"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-row align-h="center">
          <b-col md="12" class="text-center">
            <b-button
              @click="addCard()"
              variant="primary"
              class="btn-icon"
              pill
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
            <p class="mb-0 mt-50">Add Interview Questionnaire</p>
          </b-col>
          <b-col md="8">
            <b-button
              class="mt-1"
              variant="success"
              @click="saveData()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BMediaBody,
  BSpinner,
  BCardTitle,
  BCardText,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
  BSidebar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BContainer,
    BMediaAside,
    BMediaBody,
    BSpinner,
    BCardTitle,
    BCardText,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    BSidebar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      dataLoading: false,
      clsLoading: false,
      request: false,
      myObj: [
        {
          interviewData: [
            {
              id: 0,
              question: "",
              classIDs: "",
              campusID: this.$store.state.userData.cId,
            },
          ],
          cIDs: [],
        },
      ],
      allClasses: [],
      classesOptions: [],
      clsIDs: [],
      selectedIDs: [],
      currentIndex: 0,
      visibility: false,
    };
  },
  created() {
    this.LoadClasses();
    // this.LoadData();
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    getClassName(id) {
      let obj = this.allClasses.find((el) => el.id == id);
      return obj ? obj.name : "";
    },

    addCard() {
      this.myObj.push({
        interviewData: [
          {
            id: 0,
            question: "",
            classIDs: "",
            campusID: this.$store.state.userData.cId,
          },
        ],
        cIDs: [],
      });
    },
    removeCard(ind) {
      this.myObj.splice(ind, 1);
      //   if (this.myObj[ind].id == 0) {
      //     this.myObj.splice(ind, 1);
      //   } else {
      //     var status = await this.delete({
      //       url:
      //         this.$store.state.domain +
      //         "QuizQuestions/removeQuestion" +
      //         item.id +
      //         "?db=" +
      //         this.$store.state.userData.db,
      //       body: null,
      //       grading: true,
      //       context: this,
      //       token: this.$store.state.userData.token,
      //     });
      //     if (status) this.myObj.splice(ind, 1);
      //   }
    },
    AddOpen(cIndex) {
      this.currentIndex = cIndex;
      this.visibility = true;
      this.clsIDs = [];
      var elem = this.$refs["class"];
      elem.state = undefined;

      //   get all selected classes
      this.selectedIDs = this.myObj.reduce((acc, el) => {
        acc = [...acc, ...el.cIDs];
        return acc;
      }, []);
      //   console.log(this.selectedIDs);

      this.classesOptions = this.allClasses.filter(
        (el) => !this.selectedIDs.includes(el.id)
      );
      //   console.log(this.classesOptions);
    },

    Edit(cIndex) {
      this.currentIndex = cIndex;
      this.clsIDs = this.myObj[cIndex].cIDs;
      this.visibility = true;

      var elem = this.$refs["class"];
      elem.state = undefined;

      //   get all selected classes except current
      this.selectedIDs = this.myObj.reduce((acc, el, ind) => {
        if (ind != cIndex) {
          acc = [...acc, ...el.cIDs];
        }
        return acc;
      }, []);
      //   console.log(this.selectedIDs);

      this.classesOptions = this.allClasses.filter(
        (el) => !this.selectedIDs.includes(el.id)
      );
      //   console.log(this.classesOptions);
    },
    save() {
      this.visibility = false;
      this.myObj[this.currentIndex].cIDs = this.clsIDs;
      this.clsIDs = [];
    },
    addValue(item, cInd) {
      item.interviewData.push({
        id: 0,
        question: "",
        classIDs: "",
        campusID: this.$store.state.userData.cId,
      });
      this.$nextTick(() => {
        var elem = this.$refs["q-" + cInd];
        if (Array.isArray(elem) && elem.length > 0) {
          elem.at(-1).focus();
        }
      });
    },
    removeValue(item, ind) {
      // console.log(item, ind);
      item.splice(ind, 1);
      //   if (item[ind].id == 0) {
      //     item.splice(ind, 1);
      //   }
      // else {
      //   var status = await this.delete({
      //     url:
      //       this.$store.state.domain +
      //       "QuizQuestions/removeQuestion" +
      //       item.id +
      //       "?db=" +
      //       this.$store.state.userData.db,
      //     body: null,
      //     grading: true,
      //     context: this,
      //     token: this.$store.state.userData.token,
      //   });
      //   if (status) item.splice(ind, 1);
      // }
    },
    CheckValues() {
      let state = true;
      this.myObj.forEach((item) => {
        let idStr = item.cIDs.join(",");
        item.interviewData.forEach((el) => {
          el.classIDs = idStr;
          if (el.question == "") {
            state = false;
          }
        });
      });
      return state;
    },
    async saveData() {
      if (this.CheckValues() == false) {
        return this.$bvToast.toast("Please enter the details correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        //Add
        console.log(this.myObj);
        // this.request = true;
        // var status = await this.post({
        //   url:
        //     this.$store.state.domain +
        //     "GradingPolicy/SaveClassWise?db=" +
        //     this.$store.state.userData.db,
        //   body: this.myObj,
        //   message: "Grading Policy save successfully.",
        //   context: this,
        //   token: this.$store.state.userData.token,
        // });
        // if (status) this.LoadData();
        // this.request = false;
      }
    },

    async LoadClasses() {
      this.clsLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      this.allClasses = res.map((el) => el.cls);
      this.clsLoading = false;
      //   console.log(this.allClasses);
    },
    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "GradingPolicy/LoadClassWise?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj);
      if (result.length > 0) this.myObj = result;
      else {
        this.myObj = [
          {
            interviewData: [
              {
                id: 0,
                question: "",
                classIDs: "",
                campusID: this.$store.state.userData.cId,
              },
            ],
            cIDs: [],
          },
        ];
      }

      this.dataLoading = false;
    },
  },
};
</script>
<style></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
